import { IIcons } from "types/Icons";
import * as S from "./styled";

function Icons({ type, color, fill, stroke, size }: IIcons) {
 switch (type) {
  case "social-codepen":
   return (
    <S.Icon
     color={color}
     fill={fill}
     stroke={stroke}
     size={size}
     viewBox="0 2 18 22"
     aria-label="Codepen">
     <path d="M15.96 17.040l-1.68-1.16 1.68-1.28v2.44zM12.88 14.84l-3.2-2.28v-3.72l5.72 4.040-2.52 1.96zM8.8 17.92l-2.64-2.040 2.64-1.88 2.64 1.88-2.64 2.040zM7.96 12.56l-3.2 2.28-2.52-1.92 5.72-4.040v3.68zM1.68 14.6l1.68 1.28-1.68 1.16v-2.44zM4.72 16.92l3.24 2.48v3.64l-5.72-4.36 2.48-1.76zM9.64 19.4l3.24-2.48 2.48 1.76-5.72 4.36v-3.64zM8.16 6.56l-7.68 5.56c-0.28 0.2-0.48 0.56-0.48 0.92v5.6c0 0.36 0.16 0.68 0.44 0.92l7.68 5.88c0.4 0.32 0.96 0.32 1.4 0l7.68-5.88c0.28-0.2 0.44-0.56 0.44-0.92v-5.6c0-0.36-0.16-0.72-0.48-0.92l-7.68-5.56c-0.4-0.28-0.92-0.28-1.32 0z" />
    </S.Icon>
   );
  case "social-github":
   return (
    <S.Icon
     color={color}
     fill={fill}
     stroke={stroke}
     size={size}
     viewBox="0 0 24 24"
     aria-label="GitHub">
     <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
    </S.Icon>
   );
  case "social-linkedin":
   return (
    <S.Icon
     color={color}
     fill={fill}
     stroke={stroke}
     size={size}
     viewBox="0 0 300 300"
     aria-label="Linkedin">
     <path
      id="XMLID_802_"
      d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
		C77.16,101.969,74.922,99.73,72.16,99.73z"
     />
     <path
      id="XMLID_803_"
      d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
		c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"
     />
     <path
      id="XMLID_804_"
      d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
		c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
		c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
		C310,145.43,300.549,94.761,230.454,94.761z"
     />
    </S.Icon>
   );
  case "close":
   return (
    <S.Icon
     color={color}
     fill={fill}
     stroke={stroke}
     size={size}
     viewBox="0 -960 960 960"
     aria-label="Close">
     <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
    </S.Icon>
   );

  default:
   return null;
 }
}

export { Icons };
